import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<InlineNotification>`}</inlineCode>{` component is used to communicate important information to a user.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Info</Title>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Lorem ipsum:`}</strong>{` dolor sit amet, `}<a parentName="p" {...{
          "href": "/components/InlineNotification"
        }}>{`consectetur adipiscing`}</a>{` elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.`}</p>
    </InlineNotification>
    <Title mdxType="Title">Error</Title>
    <InlineNotification kind="error" mdxType="InlineNotification">
      <p><strong parentName="p">{`Error:`}</strong>{` dolor sit amet, `}<a parentName="p" {...{
          "href": "/components/InlineNotification"
        }}>{`consectetur adipiscing`}</a>{` elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.`}</p>
    </InlineNotification>
    <Title mdxType="Title">Success</Title>
    <InlineNotification kind="success" mdxType="InlineNotification">
      <p><strong parentName="p">{`Success:`}</strong>{` dolor sit amet, `}<a parentName="p" {...{
          "href": "/components/InlineNotification"
        }}>{`consectetur adipiscing`}</a>{` elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.`}</p>
    </InlineNotification>
    <Title mdxType="Title">Warning</Title>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Warning:`}</strong>{` dolor sit amet, `}<a parentName="p" {...{
          "href": "/components/InlineNotification"
        }}>{`consectetur adipiscing`}</a>{` elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.`}</p>
    </InlineNotification>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/InlineNotification/InlineNotification.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/InlineNotification",
        "path": "components/InlineNotification/InlineNotification.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/InlineNotification"
      }}>{`// Info
<InlineNotification>

**Lorem ipsum:** dolor sit amet, [consectetur adipiscing](/components/InlineNotification) elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.

</InlineNotification>

// Error
<InlineNotification kind="error">

**Error:** dolor sit amet, [consectetur adipiscing](/components/InlineNotification) elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.

</InlineNotification>

// Success
<InlineNotification kind="success">

**Success:** dolor sit amet, [consectetur adipiscing](/components/InlineNotification) elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.

</InlineNotification>

// Warning
<InlineNotification kind="warning">

**Warning:** dolor sit amet, [consectetur adipiscing](/components/InlineNotification) elit. Curabitur ac odio arcu. Vestibulum egestas eleifend porttitor. Quisque malesuada pulvinar pellentesque. Nunc dictum odio eu enim venenatis fringilla. Nunc finibus enim dui, a tempus quam commodo vitae. Donec non eros gravida dolor porta suscipit non vel quam.

</InlineNotification>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass in the children that will be rendered within the notification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specify an optional className to be applied to the container node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`kind`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode>{`, `}<inlineCode parentName="td">{`info`}</inlineCode>{`, `}<inlineCode parentName="td">{`success`}</inlineCode>{`, `}<inlineCode parentName="td">{`warning`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      